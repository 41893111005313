import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import { Container } from 'reactstrap'
import Form from '../components/form'
import { FiPhone, FiSmartphone, FiMail } from 'react-icons/fi'

const ContactPage = (props) => (
  <Layout>
    <Helmet
      title="Contact Us | Supreme Cleaning"
      meta={[
        {
          name: 'description',
          content:
            'Supreme Cleaning offer high standard window cleaning services throughout Essex and London. Get in touch for a quote today.',
        },
      ]}
    ></Helmet>
    <section className="py-5">
      <h1 className="display-3 text-center">Get in touch</h1>
    </section>
    <section className="background-blue py-5 text-center">
      <Container>
        <p className="text-light lead">
          <FiPhone /> Office: 01245 471 731
        </p>
        <p className="text-light lead">
          <FiSmartphone /> Mobile: 07403 460528
        </p>
        <p className="text-light lead">
          <FiMail /> Email:{' '}
          <a href="mailto:info@cleansupreme.co.uk">
            info@supremecleaningessex.com
          </a>
        </p>
      </Container>
    </section>
    <section className="py-5">
      <Container>
        <h2 className="display-4 mb-3 text-center">Request a quote online</h2>
        <p className="mb-5">
          If you'd like one of our team to provide you with a quote, please fill
          out the online form below with your name, address and contact details,
          and we'll be in touch to arrange a visit.
        </p>
        <Form name="contactForm" />
      </Container>
    </section>
  </Layout>
)

export default ContactPage
